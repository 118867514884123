import React from 'react';
import { Layout, Button, Input, Spin, Space, Row, Col, Modal } from 'antd';
import api from './lib/api';
import { API_URL, COBEE_VERSION } from './constants/settings';
import './App.css';
import OccupantLogin from './components/OccupantLogin';
import Activate from './components/Activate';
import AdminLogin from './components/AdminLogin';
import Forget from './components/Forget';
import Update from './components/Update';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

const axios = require('axios').default;

class App extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      loading: true,
      loginLoading: false,
      identity: null,
      username: '',
      password: '',
    };
  }

  componentWillUnmount()
  {
      if(this.internet_polling)
      {
          clearTimeout(this.internet_polling);
      }
  }

  //
  internet_polling = null; // null | object
  internet_connection = true; // true | false
  application_up_to_date = true; // true | false

  async checkInternetConnection()
  {
      var condition = navigator.onLine ? 'online' : 'offline';

      if(condition == 'online')
      {   // check server connection
          if(this.checkCobeeServerConnection())
          {
              this.internetIsOnline();
          }
          else
          {
              this.internetIsOffline();
          }
      }
      else
      {
          this.internetIsOffline();
      }

      if(this.internet_polling)
      {
          clearTimeout(this.internet_polling);
      }

      var this_obj = this;

      //
      this.internet_polling = setTimeout(() => { this_obj.checkInternetConnection(); }, 5000);
  }

  internetIsOffline()
  {
      if(this.internet_connection)
      {
          alert('Er kan geen verbinding worden gemaakt met de server. Controleer de internet verbinding.');
      }

      this.internet_connection = false;
  }

  internetIsOnline()
  {
      if(!this.internet_connection)
      {
          alert('De verbinding met de server is hersteld.');
      }

      this.internet_connection = true;
  }

  async checkCobeeServerConnection()
  {
      const timeout = new Promise((resolve, reject) => {

          setTimeout(reject, 5000, 'Request timed out');
      });

      const request = fetch(API_URL+'/polling');

      try
      {
          const response = await Promise.race([timeout, request]);

          this.checkCobeeVersion(response.headers);

          return true;
      }
      catch(error)
      {
          return false;
      }
  }

  async checkCobeeVersion(headers)
  {
      var api_version = headers.get('x-cobee-login-version');

      // newer version available
      if(api_version && COBEE_VERSION && api_version.localeCompare(COBEE_VERSION, undefined, { numeric: true, sensitivity: 'base' }))
      {
          if(this.application_up_to_date)
          {
              this.application_up_to_date = false; // mark as no longer up to date

              // show update message
              Modal.confirm({
                  title: 'Nieuwe versie beschikbaar',
                  content: 'Klik op de "Toepassen" knop om gebruik te maken van de nieuwe versie. Maar let op: u zal opnieuw in moeten loggen als u ingelogd bent. Klik op "Annuleren" om later gebruik te maken van de nieuwe versie.',
                  okText: 'Toepassen',
                  cancelText: 'Annuleren',
                  onOk(){
                      window.location.href = '/update';
                  },
                  onCancel(){},
              });
          }
      }
  }

  componentDidMount() {

      var domain = document.domain;
      // var domain = 'vanwijnen.cobee.nl';
      // console.log(document);

      api('get','/get-login-page', {'domain' : domain}, false).then((response) => {
        this.setState({identity: response.data, loading: false});
      });

      this.checkInternetConnection(); // enable on production
    }

    // login = () => {
      //     this.setState({loginLoading:true});
      //
      //     if(this.state.username != '' && this.state.password != '') {
        //         api('post','/login', {'email': this.state.username, 'password': this.state.password}, false).then((response) => {
          //             this.setState({loginLoading:false});
          //
          //             if(response.data.token) {
            //                 window.location.replace(" https://beheer.staging.cobee.nl/login?token=" + response.data.token);
            //             }
            //         });
            //     }
            // }


  render() {

      var meta_tags = document.getElementsByTagName("meta");

      var robots_meta_tag_found = false;

      if(meta_tags)
      {
          for(let meta_tag of meta_tags)
          {
              if(meta_tag.getAttribute('name') == 'robots')
              {
                  robots_meta_tag_found = true;
              }
          }
      }

      if(!robots_meta_tag_found)
      {
          document.head.innerHTML += '<meta name="robots" content="noindex,nofollow" />';
      }

    if(this.state.loading) {
      return (
        <div style={{display: 'flex', flex: 1, height:'100vh', alignItems: 'center', justifyContent: 'center'}}>
            <Spin size="large" />
        </div>
      );
    }

    return (
      <Router>
        <div style={{overflowX: 'hidden'}}>
          <Row gutter={24} style={{ minHeight: '100vh' }} type="flex" align="middle">

            <Col xs={0} sm={0} md={10} lg={8} xl={10} xxl={10} style={{ padding: 0}}>
              {this.state.identity && this.state.identity.image_url && (
                <div style={{ height: '100vh', width: '100%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundImage: `url('${this.state.identity.image_url}')` }}></div>
              )}
            </Col>
            <Col xs={24} sm={24} md={14} lg={16} xl={14} xxl={14} style={{ padding: 0}} type="flex" align="middle">
              <div style={{ height: "100%", maxWidth: "400px", padding: "24px" }}>
                <Routes>
                  <Route exact path="/" element={<OccupantLogin identity={this.state.identity} />}/>
                  <Route exact path="/update" element={<Update identity={this.state.identity} />}/>
                  <Route exact path={"/activeren"/*/:username/:code"*/} element={<Activate identity={this.state.identity} />}/>
                  <Route exact path="/beheer" element={<AdminLogin identity={this.state.identity} />}/>
                  <Route exact path="/wachtwoord-vergeten" element={<Forget occupant={true} identity={this.state.identity} />}/>
                  <Route exact path="/wachtwoord-vergeten-beheer" element={<Forget occupant={false} identity={this.state.identity} />}/>
                </Routes>
              </div>
            </Col>
          </Row>
        </div>
      </Router>
    );

  }
}

export default App;
