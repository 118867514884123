import React, { Component } from 'react';
import { Layout, Col, Row, Form, Input, Button, Alert, Typography, Spin, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import api from '../lib/api'
import '../App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { showSuccess } from '../utils/Notifications';

// const axios = require('axios').default;

class Forget extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loading: true,
      loginError: false,
      loginLoading: false,
      identity: this.props.identity || null,
    };
  }

  componentDidMount() {
    localStorage.clear();
  }

  reset = (values) => {
    this.setState({loginError: false, loginLoading:true});

    var apiUrl = this.props.occupant ? 'public/forgot-password' : 'auth/forget';
    var apiParams = this.props.occupant ? {'username': values.username } : {'email': values.username };

    api('post', apiUrl, apiParams, false).then((response) => {
      this.setState({loginLoading:false});
      showSuccess('Wachtwoord verzonden');
    });
  }

  render() {    
    const { Title, Paragraph } = Typography;

    return (
      <Row type="flex" style={{ height: "100%", flexDirection: "column", justifyContent: "space-between"}}>
        <Col>
          <img style={{width: 200, marginBottom: 48}} src={this.state.identity.logo_url} />
        </Col>
        <Col>
          <div>
          <Form onFinish={this.reset} style={{ width: '100%', textAlign: 'middle', }}>
            <Title level={2}>Wachtwoord vergeten</Title>
            <Paragraph>Vul een gebruikersnaam in om een nieuw wachtwoord in te stellen.</Paragraph>
            <Form.Item 
              name="username"
              style={{ marginBottom: '8px' }} 
              rules={[{ required: true, message: 'Vul een gebruikersnaam in'}]}
            >
              <Input prefix={<UserOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />} placeholder="Gebruikersnaam" />
            </Form.Item>

            <Button style={{ width: '100%', margin: '16px 0 0 0' }} type="primary" htmlType="submit" loading={this.loginLoading}>Versturen</Button>
            {/* <Button style={{ width: '100%', color: 'black', margin: '16px 0 0 0', background: '#' + this.state.identity.color_primary, borderColor: '#' + this.state.identity.color_primary}} onClick={this.reset} type="primary">Inloggen</Button> */}
            {window.location.href.indexOf("beheer") > -1 
              ? (<Link to="/beheer"><span style={{ display: 'block', margin: '16px 0 0 0'}}>Terug naar inloggen</span></Link>)
              : (<Link to="/"><span style={{ display: 'block', margin: '16px 0 0 0'}}>Terug naar inloggen</span></Link>)
            }
          </Form>
          </div>
        </Col>
      </Row>
    );

  }
}

export default Forget;
