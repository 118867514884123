import axios from 'axios';
import { API_URL } from '../constants/settings'

const performRequest = (method, url, params, auth, files, download) => {
    const body = method === 'get' || method === 'delete' ? 'params' : 'data';
    var config = {};

    if(files)
    {
        var formData = new FormData();

        if(files) {
          Object.keys(files).map((k) => {
            formData.append(k, files[k]);
          });
        }

        if(params) {
          Object.keys(params).map((k) => {
            if(Array.isArray(params[k])) {
              for (var i = 0; i < params[k].length; i++) {
                formData.append(k+'[]', params[k][i]);
              }
            } else {
              if(params[k]) {
                formData.append(k, params[k]);
              }

            }

          })
        }

        config = {
            method,
            url,
            baseURL: API_URL,
            [body]: formData || {}
        }

        if (auth) {
            config.headers = {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.authToken}`
            }
        }
    }
    else
    {
        config = {
            method,
            url,
            baseURL: API_URL,
            [body]: params
        }

        if (auth) {
            config.headers = {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.authToken}`
            }
        }
        // else if(method == 'post')
        // {
        //     config.headers = {
        //         'Content-Type': 'multipart/form-data'
        //     }
        // }
    }

    if(download)
    {
        config.responseType = 'arraybuffer'; //blob
    }

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {

      // if (403 === error.response.status) {
      //     if(window.location.pathname !== '/403') {
      //         window.location = '/login';
      //     }
      // }
      //
      // if (500 === error.response.status) {
      //     if(window.location.pathname !== '/500') {
      //         window.location = '/500';
      //     }
      // }
      //
      // if (401 === error.response.status) {
      //     if(window.location.pathname !== '/login') {
      //         window.location = '/login';
      //     }
      // } else {
      //     return Promise.reject(error);
      // }

    });

    return axios.request(config)
  }

  export default performRequest;
