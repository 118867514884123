import React, { Component } from 'react';
import { Layout, Col, Row, Form, Input, Button, Alert, Typography, Spin, Space } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import api from '../lib/api';
import { OCCUPANTS_URL } from '../constants/settings';
import '../App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

// const axios = require('axios').default;

class OccupantLogin extends Component {

  form = null;

  constructor(props) {

    super(props);

    this.state = {
      loading: true,
      loginError: false,
      loginLoading: false,
      identity: this.props.identity || null,
      zipcode: '',
      house_number: ''
    };
  }

  componentDidMount() {
    localStorage.clear();
  }

  login = (values) => {

    this.setState({loginError: false, loginLoading:true});

    api('post','occupant/login', {'zipcode': values.zipcode, 'house_number': values.house_number, 'password': values.password}, false).then((response) => {

      if(response && response.data.token) {

        window.location.replace(OCCUPANTS_URL + `/setlogin/${response.data.token}/${encodeURIComponent(response.data.logo)}/${encodeURIComponent(response.data.website)}/${response.data.color_primary}/${response.data.color_secondary}`);
      }
      else if(response && response.data.action && response.data.action == 'activate-account')
      {
        var host = window.location.protocol + "//" + window.location.host;

        // old
        // window.location.replace(" " + host + "/activeren?username=" + values.username + "&code=" + values.password); // + "/" + values.username + "/" + values.password);

        // new
        window.location.replace(" " + host + "/activeren?username=" + (values.zipcode + values.house_number) + "&code=" + values.password); // + "/" + values.username + "/" + values.password);
      }
      else
      {
        this.setState({loginError: true, loginLoading:false})
      }
    });
  }

  filterZipcode(string)
  {
      if(!string){ string = ''; }

      string = string.toUpperCase();

      string = string.replace(/[^a-zA-Z0-9]/ig, '');

      return string;
  }

  filterHouseNumber(string)
  {
      if(!string){ string = ''; }

      string = string.toUpperCase();

      // string = string.replace(/[^a-zA-Z0-9@._-]/ig, '');
      string = string.replace(/[^a-zA-Z0-9]/ig, '');

      return string;
  }

  render() {
    const { Title, Paragraph } = Typography;

    return (
      <Row type="flex" style={{ height: "100%", flexDirection: "column", justifyContent: "space-between"}}>
        <Col>
          <img style={{width: 200, marginBottom: 48}} src={this.state.identity.logo_url} />
        </Col>
        <Col>
          {/* {this.state.identity && this.state.identity.show_login_form === 1 && ( */}
            <div>
              <Form onFinish={this.login} style={{ width: '100%', textAlign: 'middle', }} ref={(ref) => { this.form = ref; }} >

                {/*<Alert style={{marginBottom: 10}} message="Let op!" type="error" description="Het Cobee systeem zal zaterdag 2 december door onderhoudswerkzaamheden niet beschikbaar zijn." />*/}

                {this.state.loginError ? (
                  <Alert style={{marginBottom: 16}} message="Inloggen mislukt" type="error" description="Gebruikersnaam en/of wachtwoord onjuist" closable afterClose={() => this.setState({loginError: false})} />
                ) : null}

                <Title level={2}>Inloggen</Title>

                {/*<Paragraph>Log in met een gebruikersnaam en wachtwoord.</Paragraph>
                <Form.Item
                  name="username"
                  style={{ marginBottom: '8px' }}
                  rules={[{ required: true, message: 'Vul een gebruikersnaam in'}]}
                >
                  <Input prefix={<UserOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />} placeholder="Gebruikersnaam" />
                </Form.Item>*/}

                <Paragraph>Log in met postcode, huisnummer en wachtwoord.</Paragraph>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                    <div style={{ flex: 1 }}>
                        <Form.Item
                            name="zipcode"
                            style={{ marginBottom: '8px' }}
                            rules={[{ required: true, message: 'Vul een postcode in'}]}
                            >
                            <Input
                                placeholder="Postcode"
                                onChange={(event) => { this.setState({ zipcode: this.filterZipcode(event.target.value) }, () => { if(this.form){ this.form.setFieldsValue({zipcode: this.state.zipcode}); } } ); } }
                                value={this.state.zipcode}
                                maxlength={6}
                                />
                        </Form.Item>
                    </div>
                    <div style={{ flex: 2 }}>
                        <Form.Item
                            name="house_number"
                            style={{ marginBottom: '8px' }}
                            rules={[{ required: true, message: 'Vul een huisnummer (met eventueel een toevoeging) in'}]}
                            >
                            <Input
                                placeholder="Huisnummer + toevoeging"
                                onChange={(event) => { this.setState({ house_number: this.filterHouseNumber(event.target.value) }, () => { if(this.form){ this.form.setFieldsValue({house_number: this.state.house_number}); } } ); } }
                                value={this.state.house_number}
                                />
                        </Form.Item>
                    </div>
                </div>

                <Form.Item
                  name="password"
                  style={{ marginBottom: '8px' }}
                  rules={[{ required: true, message: 'Vul een wachtwoord of activatiecode in' }]}
                >
                  <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />} type="password" placeholder="Wachtwoord of activatiecode" />
                </Form.Item>

                <Paragraph style={{ fontSize: '14px', }}>Door in te loggen ga je akkoord met onze <a target="_blank" href="https://www.cobee.nl/algemene-voorwaarden/">algemene voorwaarden</a>, <a target="_blank" href="https://www.cobee.nl/privacy/">privacybeleid</a> en <a target="_blank" href="https://www.cobee.nl/cookiebeleid/">cookiebeleid</a>.</Paragraph>
                <Button style={{ width: '100%', margin: '16px 0 0 0' }} type="primary" htmlType="submit" loading={this.loginLoading}>
                  Inloggen
                </Button>
                <Link to="/wachtwoord-vergeten"><span style={{ display: 'block', margin: '16px 0 0 0'}}>Wachtwoord vergeten</span></Link>
                <Link to="/beheer"><span style={{ display: 'block', margin: '16px 0 0 0'}}>Login voor beheerders</span></Link>
              </Form>
              {/* <Button style={{ width: '100%', color: 'black', margin: '16px 0 0 0', background: '#' + this.state.identity.color_primary, borderColor: '#' + this.state.identity.color_primary}} onClick={this.login} type="primary">Inloggen</Button> */}
            </div>
          {/* ) } */}

          {/* {this.state.identity && this.state.identity.show_login_sso_button === 1 && (
            <div>
              <Button disabled={this.state.loginLoading} href={this.state.identity.sso_button_url} type="primary">Inloggen met SSO</Button>
            </div>
          ) } */}
        </Col>
      </Row>
    );

  }
}

export default OccupantLogin;
