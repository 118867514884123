import React, { Component } from 'react';
import { Layout, Col, Row, Form, Input, Button, Alert, Typography, Spin, Space } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import api from '../lib/api';
import { ADMIN_URL } from '../constants/settings';
import '../App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

// const axios = require('axios').default;

class OccupantLogin extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loading: true,
      loginError: false,
      loginLoading: false,
      identity: this.props.identity || null,
    };
  }

  componentDidMount() {
    localStorage.clear();
  }

  login = (values) => {
    this.setState({loginError: false, loginLoading:true});

    api('post','login', {'email': values.username, 'password': values.password}, false).then((response) => {

      if(response && response.data.token) {
        window.location.replace(" " + ADMIN_URL + "/login/token/" + response.data.token);
      }
      else
      {
        this.setState({loginError: true, loginLoading:false})
      }
    });
  }

  render() {
    const { Title, Paragraph } = Typography;

    return (
      <Row type="flex" style={{ height: "100%", flexDirection: "column", justifyContent: "space-between"}}>
        <Col>
          <img style={{width: 200, marginBottom: 48}} src={this.state.identity.logo_url} />
        </Col>

        <Col>
            {this.state.identity && this.state.identity.show_login_form ? (
              <div style={{ marginBottom: '20px' }}>
                <Form onFinish={this.login} style={{ width: '100%', textAlign: 'middle', }}>

                  {/*<Alert style={{marginBottom: 10}} message="Let op!" type="error" description="Het Cobee systeem zal zaterdag 2 december door onderhoudswerkzaamheden niet beschikbaar zijn." />*/}

                  {this.state.loginError ? (
                    <Alert style={{marginBottom: 16}} message="Inloggen mislukt" type="error" description="Gebruikersnaam en/of wachtwoord onjuist" closable afterClose={() => this.setState({loginError: false})} />
                  ) : null}

                  <Title level={2}>Inloggen</Title>
                  <Paragraph>Log in met een gebruikersnaam en wachtwoord.</Paragraph>
                  <Form.Item
                    name="username"
                    style={{ marginBottom: '8px' }}
                    rules={[{ required: true, message: 'Vul een gebruikersnaam in'}]}
                  >
                    <Input prefix={<UserOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />} placeholder="Gebruikersnaam" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    style={{ marginBottom: '8px' }}
                    rules={[{ required: true, message: 'Vul een wachtwoord in' }]}
                  >
                    <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />} type="password" placeholder="Wachtwoord" />
                  </Form.Item>

                  <Paragraph style={{ fontSize: '14px', }}>Door in te loggen ga je akkoord met onze <a target="_blank" href="https://www.cobee.nl/algemene-voorwaarden/">algemene voorwaarden</a>, <a target="_blank" href="https://www.cobee.nl/privacy/">privacybeleid</a> en <a target="_blank" href="https://www.cobee.nl/cookiebeleid/">cookiebeleid</a>.</Paragraph>
                  <Button style={{ width: '100%', margin: '16px 0 0 0' }} type="primary" htmlType="submit" loading={this.loginLoading}>
                    Inloggen
                  </Button>
                  <Link to="/wachtwoord-vergeten-beheer"><span style={{ display: 'block', margin: '16px 0 0 0'}}>Wachtwoord vergeten</span></Link>
                </Form>
                {/* <Button style={{ width: '100%', color: 'black', margin: '16px 0 0 0', background: '#' + this.state.identity.color_primary, borderColor: '#' + this.state.identity.color_primary}} onClick={this.login} type="primary">Inloggen</Button> */}
              </div>
            ) : null }

            {this.state.identity && this.state.identity.show_login_sso_button === 1 && (
              <div>
                {/* <Button disabled={this.state.loginLoading} style={{ color: 'black', background: '#' + this.state.identity.color_primary, borderColor: '#' + this.state.identity.color_primary}} onClick={this.login} type="primary">Inloggen met SSO</Button> */}
                <Button disabled={this.state.loginLoading} href={this.state.identity.sso_button_url} type="primary">Inloggen met SSO</Button>
              </div>
            ) }

            <div>
                <Link to="/"><span style={{ display: 'block', margin: '16px 0 0 0'}}>Login voor bewoners</span></Link>
            </div>

          </Col>
        </Row>
    );

  }
}

export default OccupantLogin;
