import React, { Component } from 'react';

class Update extends Component {

  componentDidMount() {

      localStorage.clear();

      // TODO: admin | occupant
      document.location.href = '/?v=' + Date.now(); // fix for not rebuilding correct local storage
  }

  render() {

      return null;
  }
}

export default Update;
