import React, { Component } from 'react';
import { Layout, Col, Row, Form, Input, Button, Alert, Typography, Spin, Space } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import api from '../lib/api';
import '../App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { showSuccess, showError } from '../utils/Notifications';
import { OCCUPANTS_URL } from '../constants/settings';

class Activate extends Component {

  constructor(props) {

    super(props);

    this.state = {
      loading: true,
      loginError: false,
      loginLoading: false,
      identity: this.props.identity || null,
    };
  }

  componentDidMount() {
    // localStorage.clear();
  }

  activateAccount = (values) => {
    this.setState({loginError: false, loginLoading:true});

    if(!this.passwordValidation(values.password))
    {
        showError('Ongeldig wachtwoord: minimaal 8 tekens, waarvan 1 cijfer, 1 letter en 1 hoofdletter');
    }
    else if(values.password != values.password_repeated)
    {
        showError('De wachtwoorden komen niet overeen');
    }
    else
    {
        const urlParams = new URLSearchParams(window.location.search);

        var apiUrl = '/public/activate';
        var apiParams = {username: urlParams.get('username'), code: urlParams.get('code'), password: values.password};

        api('post', apiUrl, apiParams, false).then((response) => {
            
          this.setState({ loginLoading: false });

          showSuccess('Account geactiveerd, er kan nu worden ingelogd met het opgegeven wachtwoord.');

          if(response && response.data.token)
          {   // redirect after 2 sec.
              setTimeout(() => {

                  var username = urlParams.get('username');
                  var zipcode = username.substring(0, 6);
                  var house_number = username.substring(6);

                  api('post','occupant/login', { zipcode: zipcode, house_number: house_number, password: values.password }, false).then((response) => {

                    if(response && response.data.token)
                    {
                      window.location.replace(OCCUPANTS_URL + `/setlogin/${response.data.token}/${encodeURIComponent(response.data.logo)}/${encodeURIComponent(response.data.website)}/${response.data.color_primary}/${response.data.color_secondary}`);
                    }
                    else if(response && response.data.action && response.data.action == 'activate-account')
                    {
                      var host = window.location.protocol + "//" + window.location.host;

                      // old
                      // window.location.replace(" " + host + "/activeren?username=" + values.username + "&code=" + values.password); // + "/" + values.username + "/" + values.password);

                      // new
                      window.location.replace(" " + host + "/activeren?username=" + username + "&code=" + values.password); // + "/" + values.username + "/" + values.password);
                    }
                    else
                    {
                      this.setState({loginError: true, loginLoading:false})
                    }
                  });

              }, 2000);
          }
          else
          {   // redirect after 2 sec.
              setTimeout(() => {

                var host = window.location.protocol + "//" + window.location.host;

                window.location.replace(" " + host + "/");
              }, 2000);
          }
        });
    }
  }

  passwordValidation = (value) => {

    var validated = true;
    var error_message = '';

    var min_one_digit = /^(?=.*[0-9])/;
    var min_one_letter = /^(?=.*[a-z])/;
    var min_one_capital = /^(?=.*[A-Z])/;

    if(value) {
      if (value.length > 0 && value.length < 8) {
        validated = false;
        error_message += "minimaal 8 tekens, ";
      }
      if (!min_one_digit.test(value)) {
        validated = false;
        error_message += "minimaal 1 cijfer, ";
      }
      if(!min_one_letter.test(value)) {
        validated = false;
        error_message += "minimaal 1 letter, ";
      }
      if(!min_one_capital.test(value)) {
        validated = false;
        error_message += "minimaal 1 hoofdletter ";
      }
    }

    return validated;
  }

  render() {
    const { Title, Paragraph } = Typography;

    return (
      <Row type="flex" style={{ height: "100%", flexDirection: "column", justifyContent: "space-between"}}>
        <Col>
          <img style={{width: 200, marginBottom: 48}} src={this.state.identity.logo_url} />
        </Col>
        <Col>
          <div>
          <Form onFinish={this.activateAccount} style={{ width: '100%', textAlign: 'middle', }}>
            <Row gutter={20} style={{ padding: '10px 0' }}>
              <Col span={24} align={'left'}>

                <Title level={2}>Kies een wachtwoord</Title>

                  <Form.Item
                    name="password"
                    style={{ marginBottom: '8px' }}
                    rules={[{ required: true, message: 'Vul een wachtwoord in' }]}
                  >
                    <Input prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />} type="password" placeholder="Wachtwoord" />
                  </Form.Item>

                  <Form.Item
                    name="password_repeated"
                    style={{ marginBottom: '8px' }}
                    rules={[{ required: true, message: 'Vul een wachtwoord in' }]}
                  >
                    <Input prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />} type="password" placeholder="Herhaal wachtwoord" />
                  </Form.Item>

                <Button style={{ width: '100%' }} type="primary" htmlType="submit" loading={this.loginLoading}>Wachtwoord wijzigen</Button>

                <Link to="/"><span style={{ display: 'block', margin: '16px 0 0 0'}}>Terug naar inloggen</span></Link>
              </Col>
            </Row>
          </Form>
          </div>
        </Col>
      </Row>
    );

  }
}

export default Activate;
